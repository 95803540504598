.spinner-container {
  display: inline-flex; /* インラインフレックスアイテムとして振る舞う */
  vertical-align: middle; /* ボタンと同じ垂直位置に配置 */
  justify-content: center; /* 中央揃え */
  align-items: center; /* アイテムを中央揃えにする */
  width: 24px; /* ボタンと同じサイズ */
  height: 24px; /* ボタンと同じサイズ */
  margin: 2px 2px; /* ボタンと同じマージン */
  border-radius: 50%;

  margin-left: 10px; /* ボタンの右に適切なスペースを設定 */
  border: 4px solid rgba(0, 0, 0, 0.1); /* 薄いグレーのボーダー */
  border-top: 4px solid #0f923b; /* 緑色のボーダートップ */
  animation: spin 1s linear infinite; /* アニメーションを適用 */
}

/*
.spinner-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.spinner-container {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid blue;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
*/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
