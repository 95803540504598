.server-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.server-error-icon {
  font-size: 48px; /* アイコンのサイズ */
  color: red; /* アイコンの色 */
}

.server-error-text {
  margin-top: 10px;
  color: #333; /* テキストの色 */
  font-size: 18px; /* テキストのサイズ */
  font-weight: bold;
}
