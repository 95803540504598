/* ImageList.css */
.image-list .image-element {
  padding: 3px 3px 10px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}

.image-list .ui.medium.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image-tag {
  text-align: center;
  color: #333;
  overflow-wrap: break-word;
  word-break: break-all;
  display: block;
  margin-bottom: 0px;
}

/* 1800px 以上のビューポート幅で9列 */
@media (min-width: 1800px) {
  .image-list .image-element {
    width: 11.1%;
  }
}

/* 1600px 以上のビューポート幅で8列 */
@media (min-width: 1600px) and (max-width: 1799px) {
  .image-list .image-element {
    width: 12.5%;
  }
}
/* 1400px 以上のビューポート幅で7列 */
@media (min-width: 1400px) and (max-width: 1599px) {
  .image-list .image-element {
    width: 14.29%;
  }
}

/* 1200px 以上のビューポート幅で6列 */
@media (min-width: 1200px) and (max-width: 1399px) {
  .image-list .image-element {
    width: 16.66%;
  }
}

/* 1000px 以上のビューポート幅で5列 */
@media (min-width: 1000px) and (max-width: 1199px) {
  .image-list .image-element {
    width: 20%;
  }
}

/* 800px 以上のビューポート幅で4列 */
@media (min-width: 800px) and (max-width: 999px) {
  .image-list .image-element {
    width: 25%;
  }
}

/* 600px 以上のビューポート幅で3列 */
@media (min-width: 600px) and (max-width: 799px) {
  .image-list .image-element {
    width: 33.3%;
  }
}

/* 400px 以上のビューポート幅で2列 */
@media (min-width: 400px) and (max-width: 599px) {
  .image-list .image-element {
    width: 50%;
  }
}

/* 400px 未満のビューポート幅では全幅 */
@media (max-width: 399px) {
  .image-list .image-element {
    width: 100%;
  }
}
