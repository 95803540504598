.ui.container {
  margin-top: 10px; /* 上のマージン */
  /* 必要に応じて他のスタイルをここに追加 */
}

.search-header {
  display: flex; /* 横並びにアイテムを配置 */
  align-items: center; /* アイテムを垂直方向に中央に配置 */
  justify-content: flex-start; /* アイテムをコンテナの始めに揃える */
}

.search-logo {
  /* ロゴのサイズを設定し、必要に応じて余白を追加 */
  width: 100px; /* 例: 幅 */
  height: auto; /* アスペクト比を維持 */
  margin-right: 20px; /* ロゴとサーチバーの間にスペースを追加 */
}

.sign-out-button {
  margin-left: auto; /* これでボタンは右端に移動します */
  background-color: #0f923b; /* 緑色の背景 */
  border: none;
  color: white;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  transition-duration: 0.4s; /* ホバー時の色の変化の速さ */
  cursor: pointer;
  border-radius: 8px; /* 角の丸み */
}

.sign-out-button:hover {
  background-color: white;
  color: black;
  border: 2px solid #4caf50; /* ホバー時の境界線 */
}
