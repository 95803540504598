.search-bar {
  align-items: center; /* アイテムを中央揃えにする */
  justify-content: center; /* 中央に配置 */
  width: 80%;
  margin: 0 auto; /* 水平方向に中央揃え */
}

.search-bar .field {
  display: flex;
  justify-content: center; /* 子要素の水平方向に中央揃え */
  align-items: center; /*子要素を垂直方向に中央揃え */
  flex-wrap: wrap; /* 必要に応じて折り返す */
}

.search-field {
  flex: 1; /* 利用可能なスペースを均等に使用 */
  margin-right: 10px; /* 右の入力フィールドとの間隔 */
  min-width: 120px; /* 最小幅を設定してフィールドがあまりにも狭くならないようにする */
}

.search-field:last-child {
  margin-right: 0;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* ボタンとスピナーを右寄せにする */
}

.search-button.hidden {
  display: none; /* isLoadingがtrueの時にボタンを隠す */
}

.search-button {
  margin-left: auto;
  background-color: #0f923b; /* 緑色の背景 */
  border: none;
  color: white;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  transition-duration: 0.4s; /* ホバー時の色の変化の速さ */
  cursor: pointer;
  border-radius: 8px; /* 角の丸み */
}

.search-button:hover {
  background-color: white;
  color: black;
  border: 2px solid #4caf50; /* ホバー時の境界線 */
}
